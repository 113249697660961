import React from "react";
import "../StartApplication.scss";
import { FORM_ID } from "../../../constants/Forms";
import NavigationButtons from "../../SharedComponents/NavigationButtons";
import { UtagTracking } from "../../../utils/utag-tracking";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";
import { isCareerContract } from "../CASForms/CCB/helpers";

class MMLISIntroForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  async componentDidMount() {
    try {
      UtagTracking.sendPageNameView(
        "mmlisintroform",
        this.props.candidateId,
        this.props.agencynumber,
        this.props.contracttypeid
      );
    } catch (err) {
      console.log(err);
    }
  }

  async handleNext() {
    this.props.toggleForm(FORM_ID.MMLIS_PAY_TO_PLAY_1);
  }

  handleBackClick() {
    isCareerContract(this.props.contractType)
      ? this.props.toggleForm(FORM_ID.DIRECT_DEPOSIT)
      : this.props.mmlistoggleForm(FORM_ID.FINANCIAL_DISCLOSURE);
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <h1>MML Investors Services</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h3>Pay to Play Compliance Disclosure Form (MMLIS)</h3>
          </div>
        </div>

        <div className="row" style={{ marginTop: "25px" }}>
          <div className="col-md-12">
            SEC Rule 206(4)‐5, often referred to as the “Pay to Play” rule, and
            FINRA Rule 2030, are intended to prevent the practice of making
            campaign contributions and related payments to state and local
            government officials in order to influence the awarding of
            government advisory contracts. Pursuant to this rule, MML Investors
            Services (the “Company”) must obtain specific political contribution
            information from potential, newly hired, or existing registered
            representatives and investment advisor representatives. For details
            on reporting requirements, please refer to the Field Compliance
            Manual.
          </div>
        </div>
        <div className="row" style={{ marginTop: "25px" }}>
          <div
            className="col-md-12"
            style={{ marginLeft: "25px", paddingRight: "50px" }}
          >
            A <strong>government entity</strong> is defined as any agency,
            authority or instrumentality of a state or local government or
            political subdivision; any pool of assets sponsored by a state or
            local government (such as a defined benefit pension plan, separate
            account or general fund); any participant‐directed government plan
            (such as 403(b), or 457 plans); and officers, agents or employees of
            the state or local government or political subdivision or any
            agency, authority or instrumentality thereof, acting in their
            official capacity.
          </div>
        </div>
        <div className="row" style={{ marginTop: "25px" }}>
          <div className="col-md-12">
            Accordingly, please complete the attached "Pay to Play Compliance
            Disclosure Form." The form requires the disclosure of all applicable
            political contributions made by you, your spouse/domestic partner,
            dependent child, or business entity, directly or indirectly,
            including through a third party, within the past two years,
            including:<br></br>
            <ul className="list-unstyled">
              <li style={{ marginLeft: "25px" }}>
                (1) Any political contribution to a state or local official or
                candidate, including contributions to a federal candidate who is
                a state or local official at the time of the contribution (e.g.,
                a Governor running for U.S. Senate) or a federal official
                running for a state or local office.
              </li>
              <li style={{ marginLeft: "25px" }}>
                (2) Any contribution to any state or local political action
                committee ("PAC"), political party committee (“PPC”), or any
                other political organization.
              </li>{" "}
              <li style={{ marginLeft: "25px" }}>
                (3) Whether you control or help manage a PAC/PPC that
                contributes to a state or local official or candidate.
              </li>
              <li style={{ marginLeft: "25px" }}>
                (4) Volunteer activities for which you incurred an expense
                (e.g., you hosted and paid for a reception).
              </li>
            </ul>
          </div>
        </div>
        <div className="row" style={{ marginTop: "25px" }}>
          <div className="col-md-12">
            A <strong>contribution</strong> is defined as any monetary
            contribution, gift, subscription, loan, advance, payment of debt, or
            anything of value made for the purpose of influencing an election,
            including payment for services or the use of an individual's
            resources to benefit a campaign or committee. If you are uncertain
            as to whether a "contribution" must be disclosed, please disclose
            it.<br></br> The information contained within the form will only be
            used by the Company for the purpose of ensuring compliance with
            state and Federal Pay to Play rules.
          </div>
        </div>

        <NavigationButtons
          onBack={this.handleBackClick}
          onNext={this.handleNext}
          nxtbtnid={"mmlis_intro_next"}
          bckbtnid={"mmlis_intro_back"}
        />
      </>
    );
  }
}

export default MMLISIntroForm;
