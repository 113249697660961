import React from "react";
import { FormCustomControl } from "react-blueprint-library";
import "../StartApplication.scss";
import "../../../assets/stylesheets/StartApplication/PersonalInformation.scss";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Moment from "moment";
import InputMask from "react-input-mask";
import BackButtonModal from "../Forms/BackButtonModal";
import { putPersonalInformation } from "../../../api/makeAPIRequest";
import { FORM_ID } from "../../../constants/Forms";
import { isValidDate, clickFunc } from "../../../common/Functions";
import { CONTRACT_TYPE_DESCRIPTIONS } from "../../../constants/Contract";
import NotificationModal from "./NotificationModal";
import NavigationButtons from "../../SharedComponents/NavigationButtons";
import { UtagTracking } from "../../../utils/utag-tracking";
import { suffixList } from "../../../constants/suffixList";

class PersonalInformationForm extends React.Component {
  constructor(props) {
    super(props);

    this.genderButtonRef = React.createRef();
    this.aboutUsButtonRef = React.createRef();
    this.state = this.initialState();
    this.handleChecked = this.handleChecked.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.handleShowBackModal = this.handleShowBackModal.bind(this);
    this.handleCloseBackModal = this.handleCloseBackModal.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.toggleMiddleNameField = this.toggleMiddleNameField.bind(this);
  }

  initialState() {
    return {
      checked: false,
      selected_other: false,
      first_name: "",
      middle_name: "",
      last_name: "",
      candidate_id: "",
      suffix: "",
      preferred_first_name: "",
      preferred_last_name: "",
      ssn: "",
      dob: "",
      personal_email: "",
      personal_phone: "",
      gender_id: "",
      gender_desc: "",
      isFinOnly: false,
      hear_about_us_select_desc: "",
      hear_about_us_select_id: "",
      hear_about_us_specify: "",
      hear_about_us_select_options: [],
      prefix: "",
      showBackModal: false,
      formHasChanged: false,
      is_form_saved: false,
      ctrl_with_focus: "",
      disable_middle_name: false,
      formHasError: false,
      oktasubid: "",
      is_presentation_mode: false,
      original_ssn: "",
      hasSSN: false,
      hasDOB: false,
      isLoading: false,
      showContactAgencyModal: false,
    };
  }

  async componentDidMount() {
    try {
      const {
        hearAboutUsSelectOptions,
        personalInfoData,
        oktasubid,
        candidateId,
        email,
      } = this.props;

      if (this.genderButtonRef.current) {
        const buttonElement =
          this.genderButtonRef.current.querySelector("button");
        if (buttonElement) {
          buttonElement.setAttribute("aria-label", "Select Gender Label");
        }
      }

      if (this.aboutUsButtonRef.current) {
        const buttonElement =
          this.aboutUsButtonRef.current.querySelector("button");
        if (buttonElement) {
          buttonElement.setAttribute("aria-label", "Hear About Us Label");
        }
      }

      const isFinOnly =
        this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY;

      let option = "";
      if (!isFinOnly && personalInfoData.opportunityid !== null)
        option = hearAboutUsSelectOptions.find(
          (element) =>
            element.opportunityexpid === personalInfoData.opportunityid
        );

      this.setState({
        hear_about_us_select_options: isFinOnly ? [] : hearAboutUsSelectOptions,
        personal_email: email,
        oktasubid: oktasubid,
        candidate_id: candidateId,
        isFinOnly,
        first_name:
          personalInfoData.firstname === null ? "" : personalInfoData.firstname,
        middle_name:
          personalInfoData.middlename === null
            ? ""
            : personalInfoData.middlename,
        last_name:
          personalInfoData.lastname === null ? "" : personalInfoData.lastname,
        dob:
          personalInfoData.dateofbirth === null
            ? ""
            : personalInfoData.dateofbirth,
        ssn:
          personalInfoData.ssn === null || personalInfoData.ssn === ""
            ? ""
            : `XXX-XX-${personalInfoData.maskedssn}`,
        original_ssn: personalInfoData.ssn === null ? "" : personalInfoData.ssn,
        gender_id:
          personalInfoData.genderid === null ? "" : personalInfoData.genderid,
        gender_desc:
          personalInfoData.genderdesc === null ||
          personalInfoData.genderdesc === ""
            ? ""
            : personalInfoData.genderdesc,
        personal_phone:
          personalInfoData.phonenumber === null
            ? ""
            : personalInfoData.phonenumber,
        preferred_first_name:
          personalInfoData.preferredfirstname === null
            ? ""
            : personalInfoData.preferredfirstname,
        preferred_last_name:
          personalInfoData.preferredlastname === null
            ? ""
            : personalInfoData.preferredlastname,
        suffix: personalInfoData.suffix === null ? "" : personalInfoData.suffix,
        checked:
          personalInfoData.preferredfirstname === null
            ? false
            : personalInfoData.preferredfirstname === ""
            ? false
            : true,
        hear_about_us_select_id:
          personalInfoData.opportunityid === null || isFinOnly
            ? ""
            : personalInfoData.opportunityid,
        hear_about_us_specify: !isFinOnly
          ? personalInfoData.opportunityother
          : "",
        hear_about_us_select_desc:
          option === "" || isFinOnly ? "" : option.opportunityexpdesc,
        is_form_saved: true,
        opportunityid: null,
        disable_middle_name:
          personalInfoData.middlename === null ||
          personalInfoData.genderid === null
            ? false
            : personalInfoData.middlename === ""
            ? true
            : false,
        selected_other: isFinOnly ? "" : option.opportunityexpdesc === "Other",
        hasSSN: personalInfoData.ssn !== null && personalInfoData.ssn !== "",
        hasDOB:
          personalInfoData.dateofbirth !== null &&
          personalInfoData.dateofbirth !== "",
      });

      UtagTracking.sendPageNameView(
        "personalinformationform",
        this.props.candidateId,
        this.props.agencynumber,
        this.props.contracttypeid
      );
    } catch (err) {
      console.log(err);
    }
  }

  async handleChecked() {
    let currentCheck = this.state.checked;
    await this.setState({ checked: !currentCheck, is_form_saved: false });
    if (this.state.checked === false) {
      let prefFirstNameField = document.getElementById("preferred_first_name");
      let prefFirstNameErrorField = document.getElementById(
        "preferred_first_name_error"
      );
      prefFirstNameField.removeAttribute("style");
      prefFirstNameErrorField.removeAttribute("style");
      this.setState({ preferred_first_name: "", preferred_last_name: "" });
    }
    await this.setState({ formHasChanged: true });
    this.props.hasCurrentFormChanged(true);
  }

  async handleHearOfUsChange(event) {
    this.setState({
      hear_about_us_select_desc: event.target.innerText,
      hear_about_us_select_id: event.target.value,
      is_form_saved: false,
      formHasChanged: true,
    });

    if (event.target.innerText === "Other") {
      this.setState({ selected_other: true });
    } else {
      let hearAboutUsSpecifyField = document.getElementById(
        "hear_about_us_specify"
      );
      let hearAboutUsSpecifyErrorField = document.getElementById(
        "hear_about_us_specify_error"
      );
      hearAboutUsSpecifyField.removeAttribute("style");
      hearAboutUsSpecifyErrorField.removeAttribute("style");
      this.setState({ selected_other: false, hear_about_us_specify: "" });
    }
    //this.props.hasCurrentFormChanged(true);
  }

  async handleChange({ target: { id, value } }) {
    await this.setState({
      is_form_saved: false,
    });
    this.props.hasCurrentFormChanged(true);

    if (id.includes("gender_option")) {
      this.setState({
        gender_desc: value === "1" ? "Male" : "Female",
        gender_id: value,
      });
    } else if (id === "suffix_option_value") {
      this.setState({
        suffix: value,
      });
    } else {
      await this.setState({
        [id]: value,
      });
    }

    if (
      id !== "middle_name" &&
      id !== "suffix" &&
      id !== "preferred_last_name"
    ) {
      this.props.isFormComplete("PInfoCompleteInd", false);
    }
    await this.setState({ formHasChanged: true });
  }

  onFocus() {
    this.setState({ ctrl_with_focus: "" });

    var inputs = document.querySelectorAll("button, a");
    try {
      for (var i = 0; i < inputs.length; ++i) {
        inputs[i].addEventListener("focusout", (event) => {
          if (event.relatedTarget == null) {
            this.setState({ ctrl_with_focus: "" });
          } else {
            this.setState({ ctrl_with_focus: event.relatedTarget.id });
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async onBlur(event) {
    var obj_error;

    if (
      this.state.ctrl_with_focus.includes("option") &&
      this.state.ctrl_with_focus !== event.target.id
    ) {
      //do nothing
    } else if (
      this.state.isFinOnly &&
      (event.target.id === "first_name" ||
        event.target.id === "middle_name" ||
        event.target.id === "last_name" ||
        event.target.id === "preferred_first_name")
    ) {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length === 0) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (
      event.target.id === "first_name" ||
      event.target.id === "middle_name" ||
      event.target.id === "last_name" ||
      event.target.id === "preferred_first_name" ||
      event.target?.id === "hear_about_us_specify"
    ) {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.value.trim(" ").length === 0) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id.includes("phone")) {
      obj_error = document.getElementById(event.target.id + "_error");
      var phoneNumberErr;
      if (
        event.target.value.includes("_") ||
        event.target.value.trim(" ").length === 0
      ) {
        phoneNumberErr = true;
      } else {
        // check for 0 in start of area code - not valid
        let areaCodeCheck = event.target.value.substring(2, 3);

        if (areaCodeCheck === "0") {
          phoneNumberErr = true;
        }
      }

      if (phoneNumberErr === true) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
      }
    } else if (event.target.id.includes("gender_id")) {
      obj_error = document.getElementById("gender_error");

      if (event.target.innerText.trim(" ").length === 0) {
        event.target.style.border = "1px solid #e21224";
        obj_error.style.display = "block";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        if (event.target.className !== "dropdown-item") {
          event.target.removeAttribute("style");
        } else {
          var genderCtrl = document.getElementById(event.target.id);
          genderCtrl.style.border = "1px solid #6b748e";
        }
      }
    } else if (
      event.target.id.includes("gender_option") ||
      event.target.id === "gender_id"
    ) {
      //console.log("onBlur for GENDER_OPTION");
      obj_error = document.getElementById("gender_error");
      var ctrlToValidate = document.getElementById("gender_id");
      if (
        ((parseInt(event.target.value) !== 1 &&
          parseInt(event.target.value) !== 2) ||
          event.target.id === "gender_id") &&
        (event.relatedTarget === null ||
          event.relatedTarget.id === "personal_phone")
      ) {
        ctrlToValidate.style.border = "1px solid #e21224";
        obj_error.style.display = "block";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        if (ctrlToValidate.className !== "dropdown-item") {
          ctrlToValidate.removeAttribute("style");
        } else {
          var genderCtrl2 = document.getElementById("gender_id");
          genderCtrl2.style.border = "1px solid #6b748e";
        }
      }
    } else if (event.target.id.includes("hear_about_us_select")) {
      obj_error = document.getElementById(event.target.id + "_error");

      if (event.target.innerText.trim(" ").length === 0) {
        event.target.style.border = "1px solid #e21224";
        obj_error.style.display = "block";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        if (event.target.className !== "dropdown-item") {
          event.target.removeAttribute("style");
        } else {
          var hearAboutUsCtrl = document.getElementById(event.target.id);
          hearAboutUsCtrl.style.border = "1px solid #6b748e";
        }
      }
    } else if (
      event.target.id.includes("hear_about_option") &&
      this.state.ctrl_with_focus !== "hear_about_option"
    ) {
      //console.log("onBlur for HEAR_ABOUT_OPTION");
      obj_error = document.getElementById("hear_about_us_select_error");
      var ctrlToValidate2 = document.getElementById("hear_about_us_select");
      if (this.state.hear_about_us_select_desc.length === 0) {
        ctrlToValidate2.style.border = "1px solid #e21224";
        obj_error.style.display = "block";
        await this.setState({ formHasError: true });
      } else {
        obj_error.style.display = "none";
        if (ctrlToValidate2.className !== "dropdown-item") {
          ctrlToValidate2.removeAttribute("style");
        } else {
          var hearaboutCtrl2 = document.getElementById("hear_about_us_select");
          hearaboutCtrl2.style.border = "1px solid #6b748e";
        }
      }
    } else if (event.target.id.includes("ssn") && this.state.hasSSN === false) {
      var hasSsnError;
      obj_error = document.getElementById("ssn_error");

      if (
        event.target.value.includes("_") ||
        event.target.value.trim(" ").length === 0
      ) {
        hasSsnError = true;
      }

      var ssnregex =
        /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
      if (ssnregex.test(event.target.value) === false) {
        hasSsnError = true;
      }

      if (hasSsnError === true) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
        event.target.classList.add("validation-error");
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        event.target.classList.remove("validation-error");
      }
    } else if (event.target.id.includes("dob")) {
      var hasError;
      obj_error = document.getElementById("dob_error");

      if (
        event.target.value.includes("_") ||
        event.target.value.trim(" ").length === 0
      ) {
        obj_error.innerText = "Please enter a valid Date of Birth";
        hasError = true;
      } else if (!isValidDate(event.target.value)) {
        obj_error.innerText = "Please enter a valid Date of Birth";
        hasError = true;
      } else {
        var currDate = Moment();
        var dateParts = event.target.value.split("/");
        var month = dateParts[0];
        var day = dateParts[1];
        var year = dateParts[2];
        var dobCalc = Moment(year + "-" + month + "-" + day);
        var actualAge = currDate.diff(dobCalc, "year");

        if (dobCalc > currDate) {
          obj_error.innerText = "Date of Birth cannot be a future date";
          hasError = true;
        } else if (actualAge < 18) {
          obj_error.innerText = "You must be 18 or older to apply";
          hasError = true;
        } else if (actualAge > 98) {
          obj_error.innerText = "You must be 99 or younger to apply";
          hasError = true;
        }
      }
      if (hasError) {
        obj_error.style.display = "block";
        event.target.style.border = "1px solid #e21224";
        event.target.style.color = "#e21224";
        await this.setState({ formHasError: true });
        event.target.classList.add("validation-error");
      } else {
        obj_error.style.display = "none";
        event.target.removeAttribute("style");
        event.target.classList.remove("validation-error");
      }
    }
  }

  onKeyDown(event) {
    if (
      event.target.id !== "gender_id" &&
      event.target.id !== "hear_about_us_select"
    ) {
      this.props.isFormComplete("PInfoCompleteInd", false);
    }
    //this.props.hasCurrentFormChanged(true);
  }

  async handleNext() {
    await this.setState({ formHasError: false });

    var firstnameEvent = new Event("blur");
    Object.defineProperty(firstnameEvent, "target", {
      writable: false,
      value: document.getElementById("first_name"),
    });
    await this.onBlur(firstnameEvent);

    if (this.state.disable_middle_name === false) {
      var middlenameEvent = new Event("blur");
      Object.defineProperty(middlenameEvent, "target", {
        writable: false,
        value: document.getElementById("middle_name"),
      });
      await this.onBlur(middlenameEvent);
    }
    var lastnameEvent = new Event("blur");
    Object.defineProperty(lastnameEvent, "target", {
      writable: false,
      value: document.getElementById("last_name"),
    });
    await this.onBlur(lastnameEvent);

    if (this.state.checked) {
      var preffirstnameEvent = new Event("blur");
      Object.defineProperty(preffirstnameEvent, "target", {
        writable: false,
        value: document.getElementById("preferred_first_name"),
      });
      await this.onBlur(preffirstnameEvent);
    }
    var ssnEvent = new Event("blur");
    Object.defineProperty(ssnEvent, "target", {
      writable: false,
      value: document.getElementById("ssn"),
    });
    await this.onBlur(ssnEvent);

    var dobEvent = new Event("blur");
    Object.defineProperty(dobEvent, "target", {
      writable: false,
      value: document.getElementById("dob"),
    });
    await this.onBlur(dobEvent);

    var genderEvent = new Event("blur");
    Object.defineProperty(genderEvent, "target", {
      writable: false,
      value: document.getElementById("gender_id"),
    });
    await this.onBlur(genderEvent);

    var phoneEvent = new Event("blur");
    Object.defineProperty(phoneEvent, "target", {
      writable: false,
      value: document.getElementById("personal_phone"),
    });
    await this.onBlur(phoneEvent);

    if (!this.state.isFinOnly) {
      var hearEvent = new Event("blur");
      Object.defineProperty(hearEvent, "target", {
        writable: false,
        value: document.getElementById("hear_about_us_select"),
      });
      await this.onBlur(hearEvent);
    }

    if (
      !this.state.isFinOnly &&
      this.state.hear_about_us_select_desc === "Other"
    ) {
      var element = document.getElementById("hear_about_us_specify");
      if (element.value.trim(" ").length === 0) {
        var hear_error_other = document.getElementById(
          "hear_about_us_specify_error"
        );
        hear_error_other.style.display = "block";
        element.style.border = "1px solid #e21224";
        element.style.color = "#e21224";
        await this.setState({ formHasError: true });
        //event.target.classList.add("validation-error");
      }
    }

    if (
      this.state.formHasError === false &&
      this.state.formHasChanged === true
    ) {
      this.setState({ isLoading: true });
      var ssn =
        this.state.hasSSN || this.state.ssn.includes("X")
          ? this.state.original_ssn
          : this.state.ssn;

      let data = {
        candidateid: this.state.candidate_id,
        prefix: this.state.prefix,
        firstname: this.state.first_name,
        middlename: this.state.middle_name,
        lastname: this.state.last_name,
        suffix: this.state.suffix,
        preferredfirstname: this.state.preferred_first_name,
        preferredlastname: this.state.preferred_last_name,
        emailaddress: this.state.personal_email,
        ssn: ssn,
        dateofbirth: this.state.dob,
        genderid: parseInt(this.state.gender_id),
        opportunityid: this.state.isFinOnly
          ? null
          : this.state.hear_about_us_select_id,
        phonenumber: this.state.personal_phone,
        opportunityother:
          parseInt(this.state.hear_about_us_select_id) === 21
            ? this.state.hear_about_us_specify
            : "",
        oktasubid: this.state.oktasubid,
      };

      const pInfoData = await putPersonalInformation(
        this.state.oktasubid,
        data
      );

      if (pInfoData?.message === "Duplicate SSN") {
        this.setState({ showContactAgencyModal: true });
      } else {
        this.setState({ is_form_saved: true });
        this.props.hasCurrentFormChanged(false);
        this.props.isFormComplete("PInfoCompleteInd", true);

        //disable ssn & dob fields
        var ssn_input = document.getElementById("ssn");
        ssn_input.readOnly = true;
        var dob_input = document.getElementById("dob");
        dob_input.readOnly = true;

        if (this.state.ssn !== "") {
          await this.setState({
            original_ssn: ssn,
            hasSSN: true,
            ssn:
              "XXX-XX-" +
              this.state.ssn.substring(
                this.state.ssn.length - 4,
                this.state.ssn.length
              ),
          });
        }
        if (
          this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
          this.props.contractType ===
            CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
          this.props.contractType ===
            CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ||
          this.props.contractType ===
            CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
          this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.MMLIS ||
          this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
        ) {
          this.props.toggleForm(FORM_ID.PERSONAL_INFO_ADDITIONAL_INFO);
        } else if (
          this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY
        ) {
          this.props.toggleForm(FORM_ID.REVIEW_SIGN_1);
        } else {
          this.props.toggleForm(FORM_ID.RESIDENTIAL_ADDRESS);
        }

        this.props.savePersonalInfo("personalInformation", {
          oktasubid: this.state.oktasubid,
          candidateid: this.state.candidate_id,
          prefix: this.state.prefix,
          firstname: this.state.first_name,
          middlename: this.state.middle_name,
          lastname: this.state.last_name,
          suffix: this.state.suffix,
          preferredfirstname: this.state.preferred_first_name,
          preferredlastname: this.state.preferred_last_name,
          emailaddress: this.state.personal_email,
          ssn: this.state.ssn.includes("X")
            ? this.state.original_ssn
            : this.state.ssn,
          dateofbirth: this.state.dob,
          genderdesc: this.state.gender_desc,
          genderid: this.state.gender_id,
          opportunityid: this.state.isFinOnly
            ? null
            : parseInt(this.state.hear_about_us_select_id),
          phonenumber: this.state.personal_phone,
          opportunityother:
            parseInt(this.state.hear_about_us_select_id) === 21
              ? this.state.hear_about_us_specify
              : "",
          maskedssn: this.state.ssn.slice(-4),
        });
      }
    } else if (
      this.state.formHasError === false &&
      this.state.formHasChanged === false
    ) {
      if (
        this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.CAREER_AGENT ||
        this.props.contractType ===
          CONTRACT_TYPE_DESCRIPTIONS.CAREER_SAMPLING ||
        this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.COLLEGE_INTERN ||
        this.props.contractType ===
          CONTRACT_TYPE_DESCRIPTIONS.ALTERNATE_AGENT ||
        this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.MMLIS ||
        this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.PRELIMINARY
      ) {
        this.props.toggleForm(FORM_ID.PERSONAL_INFO_ADDITIONAL_INFO);
      } else if (
        this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY
      ) {
        this.props.toggleForm(FORM_ID.REVIEW_SIGN_1);
      } else {
        if (
          this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
        ) {
          this.props.isFormComplete("PInfoCompleteInd", true);
        }

        this.props.toggleForm(FORM_ID.RESIDENTIAL_ADDRESS);
      }
    }
  }

  handleCloseBackModal() {
    this.setState({ showBackModal: false });
  }

  handleBackClick() {
    this.setState({ formHasChanged: false });

    if (this.state.formHasError === false) {
      this.props.isFormComplete("PInfoCompleteInd", true);
    }

    this.handleCloseBackModal();

    if (this.state.isFinOnly) {
      window.location = "/";
    } else {
      this.props.toggleForm(
        this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
          ? FORM_ID.MEMBER_CORPORATE_INFO
          : FORM_ID.AUTHORIZATION
      );
    }
  }

  handleShowBackModal() {
    //show back modal if anything on the form has changed
    if (this.state.formHasChanged) {
      this.setState({ showBackModal: true });
    } else {
      if (this.state.isFinOnly) {
        window.location = "/";
      } else {
        this.props.toggleForm(
          this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP
            ? FORM_ID.MEMBER_CORPORATE_INFO
            : FORM_ID.AUTHORIZATION
        );
      }
    }
  }

  async toggleMiddleNameField(event) {
    await this.setState({
      is_form_saved: false,
    });
    await this.setState({ formHasChanged: true });
    this.props.hasCurrentFormChanged(true);

    let dmn = this.state.disable_middle_name;
    this.setState({ disable_middle_name: !dmn });
    let middleNameField = document.getElementById("middle_name");
    let middleNameError = document.getElementById("middle_name_error");

    if (dmn === false) {
      this.setState({ middle_name: "" });
      middleNameError.style.display = "none";
      middleNameField.removeAttribute("style");
      middleNameField.classList.remove("validation-error");
    }
    this.props.isFormComplete("PInfoCompleteInd", false);
  }

  render() {
    return (
      <div id="personalInformation">
        {(this.props.contractType === CONTRACT_TYPE_DESCRIPTIONS.BROKER_CORP ||
          this.props.contractType ===
            CONTRACT_TYPE_DESCRIPTIONS.MEMBER_CORP) && (
          <div className="row">
            <div className="col-md-12">
              <div className="eyebrow">
                <strong>Member of Corporation</strong>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <h1>Personal Information</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <span className="required-field-text">* = Required Field</span>
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-12">
            <label id="personal_first_name" htmlFor="first_name">
              <strong>Legal First Name*</strong>
            </label>
            <input
              type="text"
              id="first_name"
              className="form-control"
              spellCheck="false"
              maxLength={35}
              autoComplete="new-password"
              value={this.state.first_name}
              onBlur={this.onBlur}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
            />
            <div id="first_name_error" className="invalid-feedback">
              Please enter a Legal First Name
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-12">
            <label
              className={this.state.disable_middle_name ? "disable-label" : ""}
              htmlFor="middle_name"
              id="personal_middle_name"
            >
              <strong>
                Middle Name
                {this.state.disable_middle_name === false ? "*" : ""}
              </strong>
            </label>
            <input
              type="text"
              id="middle_name"
              className="form-control"
              spellCheck="false"
              maxLength={35}
              autoComplete="new-password"
              value={this.state.middle_name}
              onBlur={this.onBlur}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
              disabled={this.state.disable_middle_name}
            />
            <div id="middle_name_error" className="invalid-feedback">
              Please enter a Middle Name
            </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-12" style={{ paddingTop: "10px" }}>
            <FormCustomControl
              id="hasMiddleName"
              inputType="checkbox"
              label="I do not have a middle name"
              onChange={this.toggleMiddleNameField}
              checked={this.state.disable_middle_name}
              onMouseDown={this.onKeyDown}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-12">
            <label id="personal_last_name" htmlFor="last_name">
              <strong>Legal Last Name*</strong>
            </label>
            <input
              type="text"
              id="last_name"
              className="form-control"
              spellCheck="false"
              maxLength={35}
              autoComplete="new-password"
              value={this.state.last_name}
              onBlur={this.onBlur}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
            />
            <div id="last_name_error" className="invalid-feedback">
              Please enter a Legal Last Name
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-12">
            <label id="personal_suffix" htmlFor="suffix">
              <strong>Suffix</strong>
            </label>
            <DropdownButton
              id="suffix"
              type="default"
              variant="secondary"
              title={this.state.suffix}
              selected={false}
              ref={this.suffixButtonRef}
              autoComplete="new-password"
              aria-label="Aria Suffix"
              onSelect={(key, event) => {
                event.target.value = key;
                this.handleChange(event);
              }}
              role="button"
              onClick={clickFunc}
              onFocus={this.onFocus}
            >
              <Dropdown.Item
                eventKey={""}
                id="suffix_option_value"
                aria-label={`Aria Suffix Blank`}
              ></Dropdown.Item>
              {suffixList.map(({ value, description }) => (
                <Dropdown.Item
                  eventKey={value}
                  id="suffix_option_value"
                  aria-label={`Aria Suffix ${value}`}
                >
                  {value}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <span
              id="personal_why_we_need_this"
              style={{
                fontSize: "12px",
                color: "#555555",
              }}
            >
              Why do we need this? Including your middle name and suffix can
              speed up the application process.
            </span>
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-12" style={{ paddingTop: "10px" }}>
            <FormCustomControl
              id="hasPreferredName"
              inputType="checkbox"
              label="I have a preferred name"
              onChange={this.handleChecked}
              checked={this.state.checked}
              onMouseDown={this.onKeyDown}
            />
          </div>
        </div>
        <div
          id="preferred_names"
          className={this.state.checked ? "d-block" : "d-none"}
        >
          <div className="row" style={{ marginTop: "10px" }}>
            <div className="col-md-12">
              <label
                id="personal_preffered_first_name"
                htmlFor="preferred_first_name"
              >
                <strong>Preferred First Name*</strong>
              </label>
              <input
                type="text"
                id="preferred_first_name"
                className="form-control"
                maxLength={35}
                autoComplete="new-password"
                value={this.state.preferred_first_name}
                onBlur={this.onBlur}
                onChange={this.handleChange}
                onKeyDown={this.onKeyDown}
              />
              <div id="preferred_first_name_error" className="invalid-feedback">
                Please enter a Preferred First Name
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "10px" }}>
            <div className="col-md-12">
              <label
                id="personal_preffered_last_name"
                htmlFor="preferred_last_name"
              >
                <strong>Preferred Last Name</strong>
              </label>
              <input
                type="text"
                id="preferred_last_name"
                className="form-control"
                maxLength={35}
                autoComplete="new-password"
                value={this.state.preferred_last_name}
                onBlur={this.onBlur}
                onChange={this.handleChange}
                onKeyDown={this.onKeyDown}
              />
            </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-4">
            <label id="personal_ssn" htmlFor="ssn">
              <strong>Social Security Number*</strong>
            </label>
            <InputMask
              id="ssn"
              mask={this.state.hasSSN ? "" : "999-99-9999"}
              maskChar="_"
              className="form-control"
              placeholder="XXX-XX-XXXX"
              autoComplete="new-password"
              value={this.state.ssn}
              onBlur={this.onBlur}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
              type={this.props.candidatePresentationMode ? "password" : "text"}
              readOnly={this.state.hasSSN}
            />
            <div id="ssn_error" className="invalid-feedback">
              Please enter a Social Security Number
            </div>
          </div>
          <div className="col-md-4">
            <label id="personal_dob" htmlFor="dob">
              <strong>Date of Birth*</strong>
            </label>
            <InputMask
              id="dob"
              mask="99/99/9999"
              maskChar="_"
              className="form-control"
              placeholder="MM/DD/YYYY"
              autoComplete="new-password"
              value={this.state.dob}
              onBlur={this.onBlur}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
              readOnly={this.state.hasDOB}
            />
            <div id="dob_error" className="invalid-feedback">
              Please enter a valid Date of Birth
            </div>
          </div>
          <div className="col-md-4">
            <label
              id="personal_gender"
              aria-label="Aria Personal Gender"
              htmlFor="gender_id"
            >
              <strong>Gender*</strong>
            </label>
            <DropdownButton
              id="gender_id"
              type="default"
              variant="secondary"
              title={this.state.gender_desc}
              selected={false}
              ref={this.genderButtonRef}
              autoComplete="new-password"
              aria-label="Aria Gender"
              onSelect={(key, event) => {
                event.target.value = key;
                this.handleChange(event);
              }}
              role="button"
              onBlur={this.onBlur}
              onClick={clickFunc}
              onMouseDown={this.onKeyDown}
              onFocus={this.onFocus}
            >
              <Dropdown.Item
                eventKey={"2"}
                id="gender_option_female"
                aria-label="Aria Gender Female"
              >
                Female
              </Dropdown.Item>
              <Dropdown.Item
                eventKey={"1"}
                id="gender_option_male"
                aria-label="Aria Gender Male"
              >
                Male
              </Dropdown.Item>
            </DropdownButton>
            <div id="gender_error" className="invalid-feedback">
              Please choose Gender
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-12">
            <label id="phoneNumberLabel" htmlFor="personal_phone">
              <strong>Phone Number*</strong>
            </label>
            <InputMask
              id="personal_phone"
              mask="1-999-999-9999"
              maskChar="_"
              className="form-control"
              autoComplete="new-password"
              value={this.state.personal_phone}
              onBlur={this.onBlur}
              onChange={this.handleChange}
              onKeyDown={this.onKeyDown}
            />
            <div id="personal_phone_error" className="invalid-feedback">
              Please enter a Phone Number
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-12">
            <label id="emailLabel" htmlFor="personal_email">
              <strong>Email*</strong>
            </label>
            <input
              type="text"
              id="personal_email"
              className="form-control"
              value={this.state.personal_email}
              readOnly={true}
            />
          </div>
        </div>

        {this.props.contractType !==
          CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY && (
          <>
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-md-12">
                <label
                  id="personal_hear_about_us"
                  htmlFor="hear_about_us_select"
                >
                  <strong>How did you hear about us?*</strong>
                </label>
                <DropdownButton
                  id="hear_about_us_select"
                  type="default"
                  variant="secondary"
                  title={this.state.hear_about_us_select_desc}
                  aria-label="Aria Hear About Us Select"
                  selected={false}
                  ref={this.aboutUsButtonRef}
                  autoComplete="new-password"
                  role="button"
                  onSelect={(key, event) => {
                    event.target.value = key;
                    this.handleHearOfUsChange(event);
                  }}
                  onBlur={this.onBlur}
                  onClick={clickFunc}
                  onMouseDown={this.onKeyDown}
                  onFocus={this.onFocus}
                >
                  {this.state.hear_about_us_select_options.map((option) => (
                    <Dropdown.Item
                      key={option.opportunityexpid}
                      eventKey={option.opportunityexpid}
                      id={"hear_about_option_" + option.opportunityexpid}
                      aria-label={`aria_hear_about_us_select_${option.opportunityexpid}`}
                    >
                      {option.opportunityexpdesc}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <div
                  id="hear_about_us_select_error"
                  className="invalid-feedback"
                >
                  Please choose How you heard about us
                </div>
              </div>
            </div>

            <div
              id="selected_other"
              className={this.state.selected_other ? "d-block" : "d-none"}
            >
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-md-12">
                  <label htmlFor="hear_about_us_specify">
                    <strong>If other, please specify*</strong>
                  </label>
                  <input
                    type="text"
                    id="hear_about_us_specify"
                    className="form-control"
                    maxLength={75}
                    autoComplete="new-password"
                    value={this.state.hear_about_us_specify}
                    onBlur={this.onBlur}
                    onChange={this.handleChange}
                    onKeyDown={this.onKeyDown}
                  />
                  <div
                    id="hear_about_us_specify_error"
                    className="invalid-feedback"
                  >
                    Please enter How you heard about us
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <NavigationButtons
          onBack={this.handleShowBackModal}
          onNext={this.handleNext}
          isLoading={this.state.isLoading}
          nxtbtnid={"personal_next"}
          bckbtnid={"personal_back"}
          isFinOnly={
            this.props.contractType == CONTRACT_TYPE_DESCRIPTIONS.FINANCING_ONLY
          }
        />

        <BackButtonModal
          isOpen={this.state.showBackModal}
          closeBackModal={this.handleCloseBackModal}
          backClick={this.handleBackClick}
        />

        <NotificationModal
          isOpen={this.state.showContactAgencyModal}
          closeModal={() => {
            this.setState({ showContactAgencyModal: false, isLoading: false });
          }}
          exitApplication={() => {
            window.location = "/";
          }}
          source={"PersonalInformation"}
        />
      </div>
    );
  }
}
export default PersonalInformationForm;
