export const loadingStatus = {
  IDLE: "idle",
  PENDING: "pending",
  SUCCEDED: "succeeded",
  FAILED: "failed",
};

export const authInitialState = {
  authAccessToken: "",
  authGroups: "",
  authUserId: "",
};

export const logoutModalInitialState = {
  showLogoutModal: false,
};

export const ccb14InitialState = {
  data: {
    convictedfelony: null,
    chargedfelony: null,
    orgconvictedfelony: null,
    orgchargedfelony: null,
    convictedmisdemeanor: null,
    chargedmisdemeanor: null,
    orgconvictedmisdemeanor: null,
    orgchargedmisdemeanor: null,
    secfalsestatement: null,
    secinvolvedviolation: null,
    seccauseinvestrelatedauth: null,
    secorderagainst: null,
    secceaseanddesist: null,
    secviolatedsecuritiesact: null,
    secaidedabettersecuritiesact: null,
    secfailedtosupervise: null,
    fedfalsestatement: null,
    fedinvolvedviolation: null,
    fedcauseinvestrelatedauth: null,
    fedorderagainst: null,
    fedregdenied: null,
    finalorderbarred: null,
    finalorderviolations: null,
    selffalsestatement: null,
    selfinvolvedviolation: null,
    selfcauseinvestrelatedauth: null,
    selfdisciplined: null,
    selfviolatedsecuritiesact: null,
    selfaidedabettersecuritiesact: null,
    selffailedtosupervise: null,
    authrevoked: null,
    notifiedproceeding: null,
    notifiedinvestigation: null,
    enjoined: null,
    foundinvolvedviolation: null,
    dismissedcivilaction: null,
    namedinpendingcivilaction: null,
    litigationpending: null,
    litigationaward: null,
    litigationsettledprior: null,
    litigationsettledafter: null,
    complaintsettledprior: null,
    complaintsettledafter: null,
    complaint24monthssalesviolation: null,
    complaint24monthsforgery: null,
    violationover15k: null,
    violationaward: null,
    arbitrationsalesviolation: null,
    arbitrationforgery: null,
    termviolation: null,
    termfraud: null,
    termfailuretosupervise: null,
    tenyearscompromise: null,
    tenyearsorgcompromise: null,
    tenyearsbankruptcyboolean: null,
    criminaldisclosurestatus: null,
    regulatorystatus: null,
    civiljudstatus: null,
    custcomplaintstatus: null,
    terminationdisclstatus: null,
    financialdisclstatus: null,
    unsatisfiedjudgements: null,
    deniedbonding: null,
  },
  isFirstLoad: true,
  candidateId: null,
  isBack: false,
  error: null,
  loading: loadingStatus.IDLE,
  currentRequestId: undefined,
};

export const startApplicationInitialState = {
  dashboardInfo: "",
  releaseInfoAuthToWork: "",
  personalInformation: "",
  personalInfoHearAboutUsOptions: "",
  personalInfoAdditionalInfo: "",
  residentialAddress: "",
  residentialHistory: "",
  resAddressHasChanged: false,
  clearBusinessInformationForm: false,
  businessAddress: "",
  businessAddressChecked: false,
  brokerDealerInformation: "",
  empHistInformation: "",
  eduInformation: "",
  internEduInformation: "",
  eduDegrees: "",
  eduLevels: "",
  ccb14: "",
  characterCreditBonding: "",
  errorsAndOmissions: "",
  directDepositInformation: "",
  corporationData: "",
  corporationCCBData: "",
  corporationEOData: "",
  corporationDirectDepositData: "",
  mmlisData: "",
  mmlisObaCriaData: "",
  currentForm: 1,
  AuthorizationCompleteInd: false,
  PInfoCompleteInd: false,
  PAddlInfoCompleteInd: false,
  EduInfoCompleteInd: false,
  ProfDesInfoCompleteInd: false,
  BusAddrCompleteInd: false,
  CorpBusAddrCompleteInd: false,
  ResAddrCompleteInd: false,
  CCBCompleteInd: false,
  EOCompleteInd: false,
  BrokerCompleteInd: false,
  EmpHistCompleteInd: false,
  PSRCompleteInd: false,
  MMLISCompleteInd: false,
  MMLISPage1CompleteInd: false,
  MMLISPage2CompleteInd: false,
  MMLISOBACRIACompleteInd: false,
  RInfoCompleteInd: false,
  DirectDepositCompleteInd: false,
  CorpInfoCompleteInd: false,
  CorpCCBCompleteInd: false,
  CorpEOCompleteInd: false,
  CorpDirectDepositCompleteInd: false,
  ReviewSignButtonDisabled: true,
  resHistoryCompleteInd: false,
  oktaSubId: "",
  contractId: 0,
  candidateId: 0,
  currentFormChanged: false,
  psrData: [],
  psrChartData: [],
  psrNextEnabled: false,
  ProfessionalDesignationsData: "",
  hasNYRegs: false,
  formHasError: false,
  financial_plan_data: [],
  commission_data: [],
  total_fin_plans: 0,
  psr_data_length: 0,
  has_ind_life: false,
  has_ind_annuity: false,
  has_ind_disabilty: false,
  has_ind_long_term: false,
  has_gross_commissions: false,
  has_fee_based_sales: false,
  crimDisclosureCompleteInd: false,
  crimDisclosureCompleteStep: "0/1",
  regActionDisclosureCompleteInd: false,
  regActionDisclosureCompleteStep: "0/1",
  civilJudDisclosureCompleteInd: false,
  civilJudDisclosureCompleteStep: "0/1",
  custCompDisclosureCompleteInd: false,
  custCompDisclosureCompleteStep: "0/1",
  termDisclosureCompleteInd: false,
  termDisclosureCompleteStep: "0/1",
  finDisclosureCompleteInd: false,
  finDisclosureCompleteStep: "0/1",
  isLoading: true,
  includePSR: true,
  blankContractBlob: "",
  blankDisclosureBlob: "",
  reviewSign2Verbiage: "",
  empHistIndustries: "",
  corp_id: "",
  backFromMMLIS: false,
};
