export const suffixList = [
  {
    value: "SR.",
    description: "Senior",
  },
  {
    value: "JR.",
    description: "Junior",
  },
  {
    value: "II",
    description: "Second",
  },
  {
    value: "III",
    description: "Third",
  },
  {
    value: "IV",
    description: "Fourth",
  },
  {
    value: "V",
    description: "Fifth",
  },
  {
    value: "VI",
    description: "Sixth",
  },
  {
    value: "VII",
    description: "Seventh",
  },
  {
    value: "VIII",
    description: "Eight",
  },
];
